import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Actions from '@components/Actions';
import TickList from '@components/TickList';
import TextRenderer from '@components/TextRenderer';
import Icon from '@components/Icon';
import iconMap from '@assets/brandIcons/index';

import textStyles from '@styles/textStyles.module.css';
import flowStyles from '@styles/utilities/flow.module.css';
import * as styles from './IconWithText.module.css';

const IconWithText = ({
  heading,
  renderHeadlineAs = 'h4',
  text,
  items,
  icon,
  actions,
}) => {
  const Headline = renderHeadlineAs;

  return (
    <div className={cn(styles.root)}>
      <div className={cn(styles.iconWrapper)}>
        <Icon icon={icon} />
      </div>
      <div className={cn(styles.textWrapper)}>
        <Headline className={cn(styles.heading, textStyles.headlineS)}>
          {heading}
        </Headline>
        <TextRenderer
          className={cn(
            styles.text,
            textStyles.bodyL,
            flowStyles.root,
            flowStyles.xsmall,
          )}
          text={text}
        />
        {items && <TickList items={items} className={cn(styles.list)} />}
        {actions && <Actions actions={actions} className={styles.actions} />}
      </div>
    </div>
  );
};

IconWithText.propTypes = {
  heading: PropTypes.string.isRequired,
  renderHeadlineAs: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  text: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.oneOf(Object.keys(iconMap)).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['primary', 'secondary']),
      href: PropTypes.string,
      interaction: PropTypes.string,
    }),
  ),
};

export default IconWithText;
