import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';

import ArrowRightIcon from '@icons/ArrowRight.svg';

import Link from '@components/Link';
import { withPageSection } from '@components/PageSection';
import ThirdPartyLogo from '@components/ThirdPartyLogo';

import { makeSparkLine, sparkLineToPath } from './util';

import * as styles from './LiveData.module.css';
import StaggerAnimateIn from '@components/StaggerAnimateIn';

const cardThemes = {
  blue: {
    background: 'var(--color-accent-default)',
    backgroundHover: 'var(--color-accent-hover)',
  },
  purple: {
    background: 'var(--color-purple)',
    backgroundHover: 'var(--color-purple-hover)',
  },
  red: {
    background: 'var(--color-salmon)',
    backgroundHover: 'var(--color-salmon-hover)',
    textColor: 'rgba(0, 0, 0, 0.9)',
    borderColor: 'rgba(0, 0, 0, 0.1)',
  },
  teal: {
    background: 'var(--color-teal)',
    backgroundHover: 'var(--color-teal-hover)',
    textColor: 'rgba(0, 0, 0, 0.9)',
    borderColor: 'rgba(0, 0, 0, 0.1)',
  },
  yellow: {
    background: 'var(--color-yellow)',
    backgroundHover: 'var(--color-yellow-hover)',
    textColor: 'rgba(0, 0, 0, 0.9)',
    borderColor: 'rgba(0, 0, 0, 0.1)',
  },
  dark: {
    background: 'var(--base-color-grey-900)',
    backgroundHover: 'var(--base-color-grey-1000)',
  },
};

const SparkLine = ({ data = [] }) => {
  const points = makeSparkLine(data, 400, 100);
  const d = sparkLineToPath(points);

  const latestPoint = points[points.length - 1];

  return (
    <svg viewBox="0 0 410 100">
      <path d={d} fill="none" stroke="currentColor" strokeWidth="2" />
      <circle cx={latestPoint.x} cy={latestPoint.y} r="5" fill="currentColor" />
    </svg>
  );
};

const LiveDataCard = ({
  name,
  downloads,
  history = [],
  packages = [],
  logo,
  href = '',
  theme = 'blue',
}) => {
  const { background, backgroundHover, textColor, borderColor } =
    cardThemes[theme];

  return (
    <Link
      prefetch={false}
      className={styles.card}
      href={href}
      style={{
        '--format-background': background,
        '--format-background-hover': backgroundHover,
        '--format-text-color': textColor ?? 'var(--color-text-on-color)',
        '--format-border-color': borderColor ?? 'rgba(255, 255, 255, 0.2)',
      }}
    >
      <header className={styles.header}>
        <h3 className={styles.name}>{name}</h3>
        <ThirdPartyLogo logoKey={logo} className={styles.logo} />
      </header>

      <div className={styles.downloads}>
        <div className={styles.downloadsNumbers}>
          <div className={styles.downloadsValue}>{downloads}</div>
          <div className={styles.downloadsLabel}>downloads last 24h</div>
        </div>
        {history.length > 0 && (
          <div className={styles.downloadsSparkline}>
            <SparkLine data={history} />
          </div>
        )}
      </div>
      {packages.length > 0 && (
        <ol className={styles.packages}>
          {packages.map((pkg, index) => (
            <li key={index} className={styles.package}>
              <div className={styles.packageName}>
                {pkg.name}
                <span className={styles.packageNameVersion}>{pkg.version}</span>
              </div>
              <div className={styles.packageDownloads}>
                {pkg.downloads}
                <span className={styles.packageDownloadsLabel}>downloads</span>
              </div>
            </li>
          ))}
        </ol>
      )}

      <div className={styles.link}>
        <span className={styles.linkText}>{name} on Cloudsmith</span>
        <span className={styles.linkIcon}>
          <ArrowRightIcon />
        </span>
      </div>
    </Link>
  );
};

export const LiveData = withPageSection(({ initialData }) => {
  const [sampleData, setSampleData] = useState([]);

  const updateSampleDataWithRandomData = useCallback(() => {
    const newData = sampleData.map((data) => {
      const addDownloads = Math.floor(Math.random() * 20);
      const newHistory = [...data.history];
      newHistory.shift();
      newHistory.push(addDownloads);

      return {
        ...data,
        downloads: data.downloads + addDownloads,
        history: newHistory,
      };
    });

    setSampleData(newData);
  }, [sampleData]);

  useEffect(() => {
    setSampleData(initialData);
  }, [initialData]);

  useEffect(() => {
    let interval;
    if (sampleData.length > 0) {
      interval = setInterval(updateSampleDataWithRandomData, 5000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [sampleData, updateSampleDataWithRandomData]);

  return (
    <StaggerAnimateIn className={cn(styles.root)}>
      {sampleData.map((data, index) => (
        <LiveDataCard key={index} {...data} />
      ))}
    </StaggerAnimateIn>
  );
});
