import cn from 'classnames';

import { colorMap } from '@constants/colors';

import Actions from '@components/Actions';
import Frame from '@components/Frame';
import Illustration from '@components/Illustration';
import Image from '@components/Image';
import TextRenderer from '@components/TextRenderer';
import TickList from '@components/TickList';
import SanityImage from '@components/SanityImage';

import flowStyles from '@styles/utilities/flow.module.css';
import textStyles from '@styles/textStyles.module.css';
import measureStyles from '@styles/utilities/measures.module.css';

import * as styles from './MediaWithText.module.css';

const BaseMediaWithText = ({
  align = 'left',
  actions,
  bleed = true,
  text,
  textSize = 'medium',
  listItems,
  eyebrow,
  markerColor,
  media,
  mediaAspectRatio = '16 / 9',
  mediaMaxHeight = '600px',
  renderAs = 'div',
  theme = 'light',
  style,
  className,
}) => {
  const textClassName = cn(
    {
      // [textStyles.headlineXS]: textSize === 'small',
      // [textStyles.headlineS]: textSize === 'medium',
      // [textStyles.headlineM]: textSize === 'large',
      [textStyles.textBodyM]: textSize === 'small',
      [textStyles.textBodyL]: textSize === 'medium',
      [textStyles.textBodyXL]: textSize === 'large',
    },
    {
      [measureStyles.narrow]: textSize === 'large',
      [measureStyles.medium]: textSize === 'medium',
      [measureStyles.mediumWide]: textSize === 'small',
    },
    flowStyles.root,
    flowStyles.small,
  );

  const Tag = renderAs;
  const hasMedia = !!media;

  const cssClasses = cn(
    styles.root,
    {
      [styles.alignRight]: align === 'right',
      [styles.bleed]: bleed,
      [styles.lightTheme]: theme === 'light',
      [styles.darkTheme]: theme === 'dark',
      [styles.accentTheme]: theme === 'accent',
    },
    className,
  );

  return (
    <Tag
      className={cssClasses}
      style={{
        '--section-marker-color': markerColor && colorMap[markerColor],
        '--media-max-height': mediaMaxHeight,
        '--media-aspect-ratio': mediaAspectRatio,
        ...style,
      }}
    >
      <div className={cn(styles.text)}>
        {eyebrow && (
          <p className={cn(textStyles.headlineS, styles.eyebrow)}>{eyebrow}</p>
        )}
        <TextRenderer className={cn(textClassName)} text={text} />

        {listItems && (
          <TickList className={cn(styles.list)} items={listItems} />
        )}

        {actions && (
          <Actions className={cn(styles.actions)} actions={actions} />
        )}
      </div>
      <div className={cn(styles.media)} aria-hidden={!hasMedia || null}>
        {media}
      </div>
    </Tag>
  );
};

export const IllustrationWithText = ({ illustration, ...rest }) => {
  return (
    <BaseMediaWithText
      {...rest}
      media={<Illustration illustration={illustration} />}
    />
  );
};

export const ImageWithText = ({ image, ...rest }) => {
  return (
    <BaseMediaWithText
      {...rest}
      media={
        <div>
          {image ? (
            image.src ? (
              <Image
                fill
                src={image.src}
                alt={image.alt}
                className={cn(styles.image)}
                sizes="(min-width: 1200px) 50vw, 100vw"
              />
            ) : (
              <SanityImage
                fill
                asset={image.image.asset}
                alt={image.alt}
                className={cn(styles.image)}
                sizes="(min-width: 1200px) 50vw, 100vw"
              />
            )
          ) : null}
        </div>
      }
    />
  );
};

export const FrameWithText = ({ image, ...rest }) => {
  return (
    <BaseMediaWithText
      {...rest}
      media={
        <Frame
          size="small"
          theme={image.background ?? 'light'}
          gradient={image.gradient}
          invertGradient={image.invertGradient}
        >
          {image.src != null ? (
            <Image
              fill
              src={image.src}
              alt={image.alt}
              className={cn(styles.image)}
            />
          ) : (
            <SanityImage
              fill
              asset={image.image.asset}
              alt={image.alt}
              className={cn(styles.image)}
            />
          )}
        </Frame>
      }
    />
  );
};
