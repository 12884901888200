import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import { colorNames } from '@constants/colors';

import Gradient from './Gradient';
import * as styles from './BaseCard.module.css';

const BaseCard = ({ children, className, gradient, tag = 'div', theme }) => {
  const cssClasses = cn(styles.root, {
    // Modifier classes go here
    [styles.themeLight]: theme === 'light',
    [styles.themeDark]: theme === 'dark',
  });

  const Tag = tag;

  return (
    <Tag className={cssClasses}>
      {theme && gradient && (
        <div className={styles.gradient} aria-hidden>
          <Gradient fill={gradient} />
        </div>
      )}
      <div className={cn(styles.children, className)}>{children}</div>
    </Tag>
  );
};

BaseCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  gradient: PropTypes.oneOf(colorNames),
  tag: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
};

export default BaseCard;
