import React, { useRef, useState, useMemo } from 'react';
import cn from 'classnames';
import { useInView } from 'framer-motion';

import ArrowLink from '@components/ArrowLink';
import CardWithLogo from '@components/CardWithLogo';
import Marquee from '@components/Marquee';
import TextRenderer from '@components/TextRenderer';

import themeStyles from '@styles/themes.module.css';
import gridStyles from '@styles/layouts/grid.module.css';
import textStyles from '@styles/textStyles.module.css';
import measureStyles from '@styles/utilities/measures.module.css';
import * as styles from './FormatsIntegrationBlock.module.css';

import interactionHooks from '@util/interactions';
import { formatRoute } from '@util/routes';
import { integrationRoute } from '@util/integrations';

const MARQUEE_BASE_SPEED = 0.2;

const CardMarquee = ({ formats = [], integrations = [] }) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const combined = [...formats, ...integrations];
  const totalItems = combined.length;

  const speed = useMemo(() => {
    const speed = MARQUEE_BASE_SPEED / totalItems;
    return Math.max(0.0125, speed);
  }, [totalItems]);

  if (totalItems === 0) {
    return null;
  }

  return (
    <div
      className={cn(styles.marqueeWrapper)}
      onPointerEnter={() => setIsPlaying(false)}
      onPointerLeave={() => setIsPlaying(true)}
    >
      <Marquee speed={speed} shouldPlay={isPlaying} onlyPlayInView={false}>
        {combined.map((card) => (
          <div className={cn(styles.card)} key={card.metadata.logoId}>
            <CardWithLogo
              title={card.metadata.name}
              logo={card.metadata.logoId}
              href={
                card._type === 'format'
                  ? formatRoute(card.slug)
                  : card._type === 'integration'
                  ? integrationRoute(card.slug)
                  : card.slug
              }
            />
          </div>
        ))}
      </Marquee>
      <Marquee
        reverse
        speed={speed}
        shouldPlay={isPlaying}
        onlyPlayInView={false}
      >
        {[...combined].reverse().map((card) => (
          <div className={cn(styles.card)} key={card.metadata.logoId}>
            <CardWithLogo
              title={card.metadata.name}
              logo={card.metadata.logoId}
              href={
                card._type === 'format'
                  ? formatRoute(card.slug)
                  : card._type === 'integration'
                  ? integrationRoute(card.slug)
                  : card.slug
              }
            />
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export const FormatsIntegrationBlock = ({
  align = 'left',
  links,
  eyebrow,
  heading,
  theme = 'dark',
  formats = [],
  integrations = [],
}) => {
  // We're using an intersection observere here to only render the marquee of
  // formats and integration cards when the module is in view. This helps with
  // keeping the amount of DOM Nodes small and improves PageSpeed Score.
  const ref = useRef();
  const inView = useInView(ref, { amount: 'some', once: true });

  return (
    <div
      ref={ref}
      className={cn(styles.root, {
        [themeStyles.dark]: theme === 'dark',
        [themeStyles.light]: theme === 'light',
      })}
    >
      <div className={cn(styles.text, gridStyles.row)}>
        <div
          className={cn(gridStyles.column)}
          style={{
            '--column-span-large': 6,
            '--column-start-large': align === 'right' ? 7 : null,
          }}
        >
          {eyebrow && <h3 className={cn(textStyles.eyebrow)}>{eyebrow}</h3>}
          {heading && (
            <TextRenderer
              className={cn(textStyles.headlineL, measureStyles.narrow)}
              text={heading}
            />
          )}
          {links && (
            <div className={cn(styles.links)}>
              {links.map((link) => {
                const interaction = link.interactionId
                  ? interactionHooks[link.interactionId]()
                  : null;
                return (
                  <ArrowLink
                    key={link.label}
                    text={link.label}
                    href={link.href}
                    onClick={interaction}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
      {inView && <CardMarquee formats={formats} integrations={integrations} />}
    </div>
  );
};
