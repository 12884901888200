import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { useScroll, useMotionValueEvent } from 'framer-motion';

import TickIcon from '@icons/Tick.svg';
import CloseIcon from '@icons/Close.svg';

import visuallyHidden from '@styles/utilities/visuallyHidden.module.css';
import * as styles from './PricingTable.module.css';
import { sortPlans } from '@util/pricing';

export const PricingTable = ({
  seoHeading = 'Pricing Details',
  plans,
  features,
}) => {
  const [isInView, setIsInView] = useState(false);
  const tableRef = useRef(null);
  const prevY = useRef(null);
  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, 'change', (y) => {
    if (prevY.current !== null && y > prevY.current) {
      const tableRect = tableRef.current.getBoundingClientRect();
      const isInView = tableRect.top < 0 && tableRect.bottom > 0;
      setIsInView(isInView);
    } else {
      setIsInView(false);
    }
    prevY.current = y;
  });

  const sortedPlans = sortPlans(plans);

  return (
    <div className={cn(styles.root)}>
      <h2 className={cn(visuallyHidden.root)}>{seoHeading}</h2>

      <div ref={tableRef}>
        {features.map((feature, index) => (
          <div
            key={feature.title}
            className={cn(styles.featureBlock, {
              [styles.firstFeatureBlock]: index === 0,
            })}
          >
            <div className={cn(styles.featureTitleRow)}>
              <div className={cn(styles.featureTitle)}>
                <div className={cn(styles.featureTitleText)}>
                  {feature.title}
                </div>
                {feature.subtitle && (
                  <div className={cn(styles.featureSubtitle)}>
                    {feature.subtitle}
                  </div>
                )}
              </div>
              {index === 0 && (
                <div
                  className={cn(styles.headerRow, {
                    [styles.isSticky]: isInView,
                  })}
                >
                  {sortedPlans.map((plan) => {
                    return (
                      <div
                        key={plan.id}
                        className={cn(styles.headerCell, {
                          [styles.headerCellCustomPrice]: plan.hasCustomPrice,
                          [styles.isSticky]: isInView,
                        })}
                      >
                        <div className={cn(styles.planName)}>{plan.name}</div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            {feature.rows.map((row, i) => (
              <div key={`feature-row-${i}`} className={cn(styles.featureRow)}>
                <div className={cn(styles.featureRowTitle)}>{row.title}</div>
                {sortedPlans.map((plan) => (
                  <div
                    key={plan.id}
                    className={cn(styles.featureRowCell, {
                      [styles.featureRowCellWithPrice]: plan.price,
                      [styles.emptyFeatureRowCell]: !row[plan.id],
                      [styles.firstFeatureRowCell]: i === 0,
                      [styles.lastFeatureRowCell]:
                        i === feature.rows.length - 1,
                    })}
                  >
                    <div className={cn(styles.featureRowCellPlanName)}>
                      {plan.name}
                    </div>
                    {typeof row[plan.id] === 'boolean' ? (
                      row[plan.id] ? (
                        <TickIcon className={cn(styles.featureCellTickIcon)} />
                      ) : (
                        <CloseIcon
                          className={cn(styles.featureCellCloseIcon)}
                        />
                      )
                    ) : (
                      <div className={cn(styles.featureRowCellText)}>
                        {row[plan.id]}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
