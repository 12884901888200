import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import textStyles from '@styles/textStyles.module.css';
import * as styles from './TwitterCard.module.css';

import Image from '@components/Image';
import SanityImage from '@components/SanityImage';
import TextRenderer from '@components/TextRenderer';

const TwitterCard = ({ quote, author, twitterName, twitterAvatar }) => {
  return (
    <figure className={cn(styles.root)}>
      <blockquote className={cn(styles.quote, textStyles.headlineXS)}>
        <TextRenderer text={quote} />
      </blockquote>
      <figcaption className={cn(styles.author)}>
        {typeof twitterAvatar === 'string' ? (
          <Image
            className={cn(styles.avatar)}
            src={twitterAvatar}
            alt={author}
            width={64}
            height={64}
            sizes="64px"
          />
        ) : (
          <SanityImage
            className={cn(styles.avatar)}
            asset={twitterAvatar}
            alt={author}
            width={64}
            height={64}
            sizes="64px"
          />
        )}

        <div>
          <div className={cn(styles.name)}>{author}</div>
          {twitterName && (
            <a
              className={cn(styles.twitterName)}
              href={`https://twitter.com/${twitterName}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              @{twitterName}
            </a>
          )}
        </div>
      </figcaption>
    </figure>
  );
};

TwitterCard.propTypes = {
  quote: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  twitterName: PropTypes.string.isRequired,
  twitterAvatar: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
};

export default TwitterCard;
