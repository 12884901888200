import cn from 'classnames';

import Frame from '@components/Frame';
import Image from '@components/Image';
import SanityImage from '@components/SanityImage';
import AnimateIn from '@components/AnimateIn';
import TextRenderer from '@components/TextRenderer';
import TickList from '@components/TickList';

import gridStyles from '@styles/layouts/grid.module.css';
import textStyles from '@styles/textStyles.module.css';
import measureStyles from '@styles/utilities/measures.module.css';
import flowStyles from '@styles/utilities/flow.module.css';

import * as styles from './FeatureList.module.css';

const FeatureListItem = ({
  animate = false,
  heading,
  text,
  items = [],
  media,
  headingTag = 'h3',
}) => {
  const Heading = headingTag;

  const inner = (
    <div className={cn(gridStyles.row)}>
      <div
        className={cn(styles.itemTextColumn, gridStyles.column)}
        style={{
          '--column-span-large': 6,
        }}
      >
        <Heading className={cn(styles.heading, textStyles.headlineS)}>
          {heading}
        </Heading>
        <TextRenderer
          className={cn(
            styles.itemText,
            textStyles.bodyL,
            flowStyles.root,
            flowStyles.xsmall,
            measureStyles.medium,
          )}
          text={text}
        />
        {items && items.length > 0 && (
          <TickList items={items} className={cn(styles.itemList)} />
        )}
      </div>
      <div
        className={cn(styles.itemMediaColumn, gridStyles.column)}
        style={{
          '--column-span-large': 6,
        }}
      >
        {media}
      </div>
    </div>
  );

  return (
    <li className={cn(styles.item)}>
      {animate ? <AnimateIn>{inner}</AnimateIn> : inner}
    </li>
  );
};

export const FeatureList = ({ items, animateItemsIn = false }) => {
  return (
    <div>
      <ol className={cn(styles.root)}>
        {items.map((item, i) => (
          <FeatureListItem
            key={i}
            animate={animateItemsIn}
            heading={item.heading}
            text={item.description}
            items={item.listItems}
            media={
              <Frame
                size="small"
                theme={item.image.background ?? 'light'}
                gradient={item.image.gradient}
                invertGradient={item.image.invertGradient}
                bleed={item.image.bleed}
              >
                {item.image.src != null ? (
                  <Image
                    fill
                    src={item.image.src}
                    alt={item.image.alt}
                    sizes="(min-width: 960px) 50vw, 100vw"
                  />
                ) : (
                  <SanityImage
                    fill
                    asset={item.image.image.asset}
                    alt={item.image.alt}
                    sizes="(min-width: 960px) 50vw, 100vw"
                  />
                )}
              </Frame>
            }
          />
        ))}
      </ol>
    </div>
  );
};
