import { ImageWithText } from '@blocks/MediaWithText';
import { readableDate } from '@util/date';

export const FeaturedPressRelease = ({ heading, pressRelease, cta_text }) => {
  const actions = [
    {
      label: cta_text,
      href: `/company/press/${pressRelease.slug}`,
    },
  ];

  return (
    <ImageWithText
      align="right"
      bleed={false}
      border={false}
      image={pressRelease.image}
      text={heading}
      eyebrow={readableDate(pressRelease.date)}
      actions={actions}
    />
  );
};
