import cn from 'classnames';

import * as styles from './ProductOverview.module.css';
import interactionHooks from '@util/interactions';
import Button from '@components/Button';
import { useState } from 'react';
import SanityImage from '@components/SanityImage';

export const ProductOverview = ({ slides, cta }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const interaction = cta.interaction
    ? interactionHooks[cta.interaction]()
    : null;

  return (
    <div className={cn(styles.root)}>
      <div className={cn(styles.header)}>
        <div className={cn(styles.slideButtonsWrapper)}>
          <div className={cn(styles.buttons)}>
            {slides.map((slide, i) => (
              <Button
                colorScheme={i === currentSlide ? 'dark' : 'light'}
                key={i}
                className="slideButton"
                onClick={() => setCurrentSlide(i)}
              >
                {slide.title}
              </Button>
            ))}
            <div className={cn(styles.mask)}></div>
          </div>
        </div>
        <Button variant={cta.type} href={cta.href} onClick={interaction}>
          {cta.label}
        </Button>
      </div>
      <div className={cn(styles.slides)}>
        {slides.map((slide, i) => (
          <div
            key={i}
            className={cn(styles.slide, i === currentSlide && styles.active)}
          >
            <SanityImage asset={slide.image.image} />
          </div>
        ))}
      </div>
    </div>
  );
};
