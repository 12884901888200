import Illustration, {
  QuarterTurn,
  Package,
} from '@components/Illustration/blocks';

export default function Gradient() {
  return (
    <Illustration width={1200} height={1200} size={2500}>
      <QuarterTurn
        x={0.125}
        y={0.125}
        fill="purple"
        horizontal="left"
        vertical="up"
      />
      <Package direction="right" x={0.5} y={0.125} />
    </Illustration>
  );
}
