import React from 'react';
import cn from 'classnames';

import PricingCard from '@components/PricingCard';

import visuallyHidden from '@styles/utilities/visuallyHidden.module.css';
import * as styles from './PricingCards.module.css';
import { sortPlans } from '@util/pricing';

export const PricingCards = ({ seoHeading = 'Pricing Plans', plans }) => {
  return (
    <>
      <div className={cn(styles.root)}>
        <h2 className={cn(visuallyHidden.root)}>{seoHeading}</h2>
        {sortPlans(plans).map((plan, index) => (
          <PricingCard
            key={plan.id}
            animationDelay={(index + 1) * 0.5}
            className={styles.card}
            unobtrusive={plan.hasCustomPrice}
            {...plan}
          />
        ))}
      </div>
    </>
  );
};
