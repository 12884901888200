import React, { useState, useMemo, useEffect } from 'react';
import cn from 'classnames';

import CardWithLogo from '@components/CardWithLogo';
import TextRenderer from '@components/TextRenderer';

import textStyles from '@styles/textStyles.module.css';
import measureStyles from '@styles/utilities/measures.module.css';
import flowStyles from '@styles/utilities/flow.module.css';
import * as styles from './TypeListing.module.css';
import { formatRoute } from '@util/routes';
import { integrationRoute } from '@util/integrations';

export const TypeListing = ({
  entries,
  categories: _categories,
  resetText = 'Show all Formats',
}) => {
  const [activeCategory, setActiveCategory] = useState(null);

  const categories = useMemo(() => {
    return _categories.map((category) => {
      return {
        ...category,
        count: entries.filter((f) => f.metadata.category === category.name)
          .length,
      };
    });
  }, [entries, _categories]);

  const visibleEntries = useMemo(() => {
    if (activeCategory) {
      return entries.filter((entry) => {
        return entry.metadata.category === activeCategory.name;
      });
    }
    return entries;
  }, [entries, activeCategory]);

  useEffect(() => {
    setActiveCategory(null);
  }, [entries]);

  return (
    <div
      className={cn(styles.root, {
        [styles.hasActiveCategory]: activeCategory,
      })}
    >
      <nav className={cn(styles.nav)}>
        {categories.map((category) => {
          return (
            <button
              key={category.name}
              className={cn(styles.navButton)}
              onClick={() => {
                setActiveCategory(category);
              }}
              aria-current={
                category.name === activeCategory?.name ? 'page' : null
              }
            >
              {category.name}
              <span className={cn(styles.navButtonCount)}>
                {category.count}
              </span>
            </button>
          );
        })}
        {activeCategory && (
          <button
            className={cn(styles.resetButton)}
            onClick={() => {
              setActiveCategory(null);
            }}
          >
            {resetText}
          </button>
        )}
      </nav>
      <div className={cn(styles.formatListWrapper)}>
        {activeCategory && (
          <div className={cn(styles.categoryInformation)}>
            <h2 className={cn(styles.categoryTitle, textStyles.eyebrow)}>
              {activeCategory.name}
            </h2>
            <TextRenderer
              className={cn(
                styles.categoryDescription,
                textStyles.bodyL,
                flowStyles.root,
                flowStyles.xsmall,
                measureStyles.medium,
              )}
              text={activeCategory.intro ?? ''}
            />
          </div>
        )}
        <div className={cn(styles.formatList)}>
          {visibleEntries.map((format) => {
            return (
              <CardWithLogo
                key={format.metadata.name}
                title={format.metadata.name}
                text={activeCategory ? format.metadata.teaser : null}
                href={
                  format._type === 'format'
                    ? formatRoute(format.slug)
                    : format._type === 'integration'
                    ? integrationRoute(format.slug)
                    : format.slug
                }
                logo={format.metadata.logoId}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
