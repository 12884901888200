import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Actions from '@components/Actions';
import TickList from '@components/TickList';
import TextRenderer from '@components/TextRenderer';
import Icon from '@components/Icon';
import iconMap from '@assets/brandIcons/index';

import textStyles from '@styles/textStyles.module.css';
import flowStyles from '@styles/utilities/flow.module.css';
import * as styles from './CardWithIcon.module.css';

const CardWithIcon = ({
  heading,
  renderHeadlineAs = 'h4',
  text,
  items,
  icon,
  actions,
}) => {
  const Headline = renderHeadlineAs;

  return (
    <div className={cn(styles.root)}>
      {icon && (
        <div className={cn(styles.iconWrapper)}>
          <Icon icon={icon} />
        </div>
      )}
      <Headline className={cn(styles.heading, textStyles.headlineXS)}>
        {heading}
      </Headline>
      <TextRenderer
        className={cn(
          styles.text,
          textStyles.bodyM,
          flowStyles.root,
          flowStyles.xsmall,
        )}
        text={text}
      />

      {items && <TickList items={items} className={cn(styles.list)} />}

      {actions && <Actions actions={actions} className={styles.actions} />}
    </div>
  );
};

CardWithIcon.propTypes = {
  heading: PropTypes.string.isRequired,
  renderHeadlineAs: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  text: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.oneOf(Object.keys(iconMap)),
};

export default CardWithIcon;
