import cn from 'classnames';

import Image from '@components/Image';
import Frame from '@components/Frame';
import Illustration from '@components/Illustration';
import VideoPlayer from '@components/VideoPlayer';

import * as styles from './MediaBlock.module.css';

const BaseMediaBlock = ({
  media,
  mediaAspectRatio = '16 / 9',
  mediaMaxHeight = '600px',
  renderAs = 'div',
  theme = 'light',
  style,
  className,
}) => {
  const Tag = renderAs;
  const hasMedia = !!media;

  const cssClasses = cn(
    styles.root,
    {
      [styles.lightTheme]: theme === 'light',
      [styles.darkTheme]: theme === 'dark',
    },
    className,
  );

  return (
    <Tag
      className={cssClasses}
      style={{
        '--media-max-height': mediaMaxHeight,
        '--media-aspect-ratio': mediaAspectRatio,
        ...style,
      }}
    >
      <div className={cn(styles.media)} aria-hidden={!hasMedia || null}>
        {media}
      </div>
    </Tag>
  );
};

export const IllustrationMediaBlock = ({ illustration, ...rest }) => {
  return (
    <BaseMediaBlock
      {...rest}
      media={<Illustration illustration={illustration} />}
    />
  );
};

export const ImageMediaBlock = ({ image, ...rest }) => {
  return (
    <BaseMediaBlock
      {...rest}
      media={
        <div>
          <Image
            src={image.src}
            alt={image.alt}
            fill
            className={cn(styles.image)}
            sizes="(min-width: 1200px) 50vw, 100vw"
          />
        </div>
      }
    />
  );
};

export const FrameMediaBlock = ({ image, ...rest }) => {
  return (
    <BaseMediaBlock
      {...rest}
      media={
        <Frame
          size="small"
          theme={image.background ?? 'light'}
          gradient={image.gradient}
          invertGradient={image.invertGradient}
        >
          <Image
            src={image.src}
            alt={image.alt}
            fill
            className={cn(styles.image)}
          />
        </Frame>
      }
    />
  );
};

export const VideoMediaBlock = ({ video, ...rest }) => {
  return (
    <BaseMediaBlock
      {...rest}
      media={
        <div>
          <VideoPlayer
            title={video.title}
            thumbnail={video.thumbnail}
            id={video.id}
          />
        </div>
      }
    />
  );
};
