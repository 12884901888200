import React from 'react';
import cn from 'classnames';

import interactionHooks from '@util/interactions';
import illustrationMap from './Illustrations';

import Button from '@components/Button';

import * as styles from './PricingCard.module.css';

const PricingCard = ({
  id,
  className,
  eyebrow,
  name,
  price,
  description,
  hasCustomPrice,
  hasStartingPrice,
  action,
  highlightFeatures,
  animationDelay = 0,
  unobtrusive = false,
  compact = false,
  variant,
  trackingScope = 'PricingCards',
}) => {
  const Illustration = illustrationMap[id] ?? illustrationMap.pro;

  const classNames = cn(
    styles.card,
    {
      [styles.unobtrusive]: unobtrusive,
      [styles[variant]]: variant,
    },
    className,
  );

  const interaction = action.interaction
    ? interactionHooks[action.interaction]()
    : null;

  return (
    <div className={cn(classNames)}>
      <div className={cn(styles.cardHeader)}>
        <div className={cn(styles.cardIllustration)}>
          <Illustration animationDelay={animationDelay} />
        </div>
        {eyebrow && <div className={cn(styles.cardEyebrow)}>{eyebrow}</div>}
        <div className={cn(styles.cardPriceRow)}>
          <div className={cn(styles.cardName)}>{name}</div>
          {hasCustomPrice && !hasStartingPrice && (
            <div className={cn(styles.cardPriceCustom)}>
              Custom Annual Pricing
            </div>
          )}
          {hasCustomPrice && hasStartingPrice && (
            <div className={cn(styles.cardPrice)}>
              <span className={cn(styles.cardStartingPrice)}>Starting at</span>{' '}
              ${price.toString()}
            </div>
          )}
          {!hasCustomPrice && (
            <div className={cn(styles.cardPrice)}>
              ${price.toString()}
              <span className={cn(styles.cardPricePeriod)}>
                &thinsp;/&thinsp;month
              </span>
            </div>
          )}
        </div>
        <div className={cn(styles.cardDescription)}>{description}</div>

        {compact && (
          <div className={styles.compactCardAction}>
            <Button
              fullWidth
              withArrow={!hasCustomPrice}
              variant={unobtrusive ? 'secondary' : 'primary'}
              size={variant === 'large' ? 'large' : null}
              href={action.href}
              onClick={interaction}
              trackingScope={trackingScope}
              isStartFreeTrial={action.isStartFreeTrial ?? null}
            >
              {action.label}
            </Button>
          </div>
        )}
      </div>

      {!compact && (
        <div className={cn(styles.cardBody)}>
          <ul className={cn(styles.cardFeatures)}>
            {highlightFeatures.map((feature) => (
              <li key={feature.title} className={cn(styles.cardFeature)}>
                <div className={cn(styles.cardFeatureTitle)}>
                  {feature.title}
                </div>
                <div className={cn(styles.cardFeatureValue)}>
                  {feature.value}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default PricingCard;
