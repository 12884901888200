import { makeAnimationOffsetPath } from '@components/Illustration/util';
import Illustration, {
  Horizontal,
  QuarterTurn,
  Package,
} from '@components/Illustration/blocks';

const WIDTH = 400;
const HEIGHT = 250;
const SIZE = 100;

const packageAnimationPath = makeAnimationOffsetPath({
  width: WIDTH,
  height: HEIGHT,
  size: SIZE,
  commands: [
    {
      type: 'move',
      x: -4,
      y: 0,
    },
    {
      type: 'line',
      x: 0,
      y: 0,
    },
  ],
});

const CoreIllustration = ({ animationDelay = 0 }) => {
  return (
    <Illustration width={WIDTH} height={HEIGHT} size={SIZE}>
      <Horizontal x={-4} y={0} length={3} stroke="white" opacity={0.5} />
      <Horizontal x={-1.25} y={0} length={1.5} fill="white" opacity={0.2} />
      <Package
        animationDuration={1}
        animationDelay={animationDelay}
        animationPath={packageAnimationPath}
        x={0}
        y={0}
        direction="right"
        size={SIZE}
      />
    </Illustration>
  );
};

const ProIllustration = ({ animationDelay = 0 }) => {
  return (
    <Illustration width={WIDTH} height={HEIGHT} size={SIZE}>
      <Horizontal x={-4} y={0} length={3} stroke="white" opacity={0.5} />
      <Horizontal x={-1.25} y={0} length={1.5} fill="blue" />
      <Package
        animationDuration={1}
        animationDelay={animationDelay}
        animationPath={packageAnimationPath}
        x={0}
        y={0}
        direction="right"
        size={SIZE}
      />
    </Illustration>
  );
};

const VelocityIllustration = ({ animationDelay = 0 }) => {
  return (
    <Illustration width={WIDTH} height={HEIGHT} size={SIZE}>
      <Horizontal x={-1.25} y={0} length={3.5} fill="blue" />
      <Horizontal x={-4} y={0} length={3} fill="yellow" opacity={0.5} />
      <Package
        animationDuration={1}
        animationDelay={animationDelay}
        animationPath={packageAnimationPath}
        x={0}
        y={0}
        direction="right"
        size={SIZE}
      />
    </Illustration>
  );
};

const UltraIllustration = ({ animationDelay = 0 }) => {
  return (
    <Illustration
      width={WIDTH}
      height={HEIGHT}
      size={SIZE}
      background="transparent"
    >
      <Horizontal x={-4.05} y={0} length={3} fill="blue" opacity={0.5} />
      <QuarterTurn
        x={-1.25}
        y={0}
        horizontal="left"
        vertical="up"
        fill="purple"
      />
      <Horizontal x={-1.25} y={0} length={3.5} fill="teal" />
      <Package
        animationDuration={1}
        animationDelay={animationDelay}
        animationPath={packageAnimationPath}
        x={0}
        y={0}
        direction="right"
        size={SIZE}
      />
    </Illustration>
  );
};

const illustrationMap = {
  core: CoreIllustration,
  pro: ProIllustration,
  velocity: VelocityIllustration,
  ultra: UltraIllustration,
};

export default illustrationMap;
