import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { zeroPad } from '@util/numbers';

import ArrowLeft from '@icons/ArrowLeft.svg';
import ArrowRight from '@icons/ArrowRight.svg';

import * as styles from './Carousel.module.css';

const PAN_THRESHOLD = 10;

const Carousel = ({ stretch = true, children, style, className }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const touchStart = useRef(null);
  const touchEnd = useRef(null);

  const totalElementCount = React.Children.count(children);

  const handleTouchStart = (e) => {
    touchEnd.current = null;
    touchStart.current = e.clientX;
  };

  const handleTouchEnd = (e) => {
    touchEnd.current = e.clientX;

    const dist = touchEnd.current - touchStart.current;

    if (dist < PAN_THRESHOLD * -1) {
      handleNext();
    }
    if (dist > PAN_THRESHOLD) {
      handlePrev();
    }
  };

  const handlePrev = () => {
    if (activeIndex === 0) return;
    setActiveIndex(activeIndex - 1);
  };

  const handleNext = () => {
    if (activeIndex === totalElementCount - 1) return;
    setActiveIndex(activeIndex + 1);
  };

  return (
    <div
      className={cn(
        styles.root,
        {
          [styles.stretch]: stretch,
        },
        className,
      )}
      onPointerDown={handleTouchStart}
      onPointerUp={handleTouchEnd}
      style={{
        '--carousel-item': activeIndex,
        ...style,
      }}
    >
      <div className={cn(styles.controls)}>
        <span className={cn(styles.counter)}>
          {zeroPad(activeIndex + 1, 2)}
          <span className={cn(styles.counterDivider)}>/</span>
          {zeroPad(totalElementCount, 2)}
        </span>
        <div>
          <button
            className={cn(styles.control, styles.prev)}
            onClick={handlePrev}
            disabled={activeIndex === 0}
            aria-label="Previous"
          >
            <ArrowLeft />
          </button>
          <button
            className={cn(styles.control, styles.next)}
            onClick={handleNext}
            disabled={activeIndex === totalElementCount - 1}
            aria-label="Next"
          >
            <ArrowRight />
          </button>
        </div>
      </div>
      <div className={cn(styles.children)}>
        <div className={cn(styles.itemWrapper)}>
          {React.Children.map(children, (child) => {
            return <div className={cn(styles.item)}>{child}</div>;
          })}
        </div>
      </div>
    </div>
  );
};

Carousel.propTypes = {
  stretch: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  animate: PropTypes.bool,
};

export default Carousel;
