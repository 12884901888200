import React from 'react';
import cn from 'classnames';

import { packageRoute } from '@util/routes';

import Actions from '@components/Actions';
import Carousel from '@components/Carousel';
import PackageMiniCard from '@components/navigator/PackageMiniCard';

import Gradient from './Gradient';

import * as themeStyles from '@styles/themes.module.css';
import * as textStyles from '@styles/textStyles.module.css';
import * as measureStyles from '@styles/utilities/measures.module.css';
import * as styles from './PackagesBlock.module.css';

export const PackagesBlock = ({ eyebrow, intro, actions, packages }) => {
  return (
    <div className={cn(styles.root, themeStyles.purple)}>
      <div className={styles.gradient} aria-hidden>
        <Gradient />
      </div>

      <div className={styles.content}>
        <h2 className={textStyles.eyebrow}>{eyebrow}</h2>
        <div className={cn(textStyles.headlineM, measureStyles.narrow)}>
          {intro}
        </div>

        {actions && actions.length > 0 && (
          <Actions actions={actions} className={styles.actions} />
        )}

        <div className={styles.carousel}>
          <Carousel
            animate
            stretch
            style={{
              '--carousel-item-width': 'min(500px, 70vw)',
            }}
          >
            {packages.map((pkg) => (
              <PackageMiniCard
                key={`${pkg.registry}/${pkg.name}`}
                name={pkg.name}
                registry={pkg.registry}
                keywords={pkg.latestVersion.keywords}
                description={pkg.description}
                latestVersion={pkg.latestVersion.version}
                latestReleaseDate={pkg.latestVersion.released}
                licenses={pkg.latestVersion.licenses}
                href={packageRoute(pkg.registry, pkg.name)}
              />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};
