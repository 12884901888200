import React from 'react';
import cn from 'classnames';
import { dividerHeights } from '@constants/measurements';

import * as styles from './Divider.module.css';

export const Divider = ({ withBorder = false, size = 'medium' }) => {
  return (
    <hr
      aria-hidden
      className={cn(styles.root, { [styles.withBorder]: withBorder })}
      style={{
        '--divider-height': dividerHeights[size],
      }}
    />
  );
};
