import cn from 'classnames';

import interactionHooks from '@util/interactions';
import { formatNumber } from '@util/numbers';

import * as styles from './OnDemandPricing.module.css';

const OnDemandPricing = ({ additionalPricing, committedAdditionalPricing }) => {
  const openChat = interactionHooks.openChat();

  return (
    <div className={cn(styles.root)}>
      {additionalPricing.show && (
        <div className={cn(styles.row, styles.rowAdditional)}>
          <div className={cn(styles.rowDetails)}>
            <span className={cn(styles.rowPrimaryDetail)}>
              <span className={cn(styles.rowTitle)}>
                {additionalPricing.description}
              </span>
              {additionalPricing.plans && (
                <span className={cn(styles.badges)}>
                  {additionalPricing.plans.map((plan, index) => {
                    return (
                      <span key={index} className={cn(styles.badge)}>
                        {plan.name}
                      </span>
                    );
                  })}
                </span>
              )}
            </span>
            <span className={cn(styles.rowSecondaryDetail)}>
              {additionalPricing.price && (
                <span>${formatNumber(additionalPricing.price, 2)}</span>
              )}
              {additionalPricing.perUnit && (
                <span className={cn(styles.unit)}>
                  &thinsp;/&thinsp;{additionalPricing.perUnit}
                </span>
              )}
            </span>
          </div>
        </div>
      )}
      {committedAdditionalPricing.show && (
        <div className={cn(styles.row, styles.rowCommittedAdditional)}>
          <div className={cn(styles.rowDetails)}>
            <span className={cn(styles.rowPrimaryDetail)}>
              <span className={cn(styles.rowTitle)}>
                {committedAdditionalPricing.description}
              </span>
              {committedAdditionalPricing.plans && (
                <span className={cn(styles.badges)}>
                  {committedAdditionalPricing.plans.map((plan, index) => {
                    return (
                      <span key={index} className={cn(styles.badge)}>
                        {plan.name}
                      </span>
                    );
                  })}
                </span>
              )}
            </span>
            <span className={cn(styles.rowSecondaryDetail)}>
              <button className={cn(styles.contactButton)} onClick={openChat}>
                {committedAdditionalPricing.link}
              </button>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default OnDemandPricing;
