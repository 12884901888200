import React from 'react';
import cn from 'classnames';

import Image from '@components/Image';
import SanityImage from '@components/SanityImage';
import TextRenderer from '@components/TextRenderer';

import textStyles from '@styles/textStyles.module.css';
import * as styles from './Quote.module.css';

export const Quote = ({ text, author, position, companyLogo }) => {
  return (
    <figure className={cn(styles.root)}>
      <blockquote className={cn(styles.quote, textStyles.headlineL)}>
        <TextRenderer text={text} />
      </blockquote>
      <figcaption className={cn(styles.author)}>
        {companyLogo && (
          <div className={cn(styles.logoWrapper)}>
            {companyLogo.src != null ? (
              <Image
                className={cn(styles.logo)}
                src={companyLogo.src}
                alt={companyLogo.alt}
                fill
                sizes="80px"
              />
            ) : (
              <SanityImage
                className={cn(styles.logo)}
                asset={companyLogo.image.asset}
                alt={companyLogo.alt}
                fill
                sizes="80px"
              />
            )}
          </div>
        )}
        <div className={cn(styles.authorInfo)}>
          <p className={cn(styles.authorName)}>{author}</p>
          {position && <p className={cn(styles.authorPosition)}>{position}</p>}
        </div>
      </figcaption>
    </figure>
  );
};
