import React, { useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import { colorMap } from '@constants/colors';

import AnimateIn from '@components/AnimateIn';
import CardWithIcon from '@components/CardWithIcon';
import TextRenderer from '@components/TextRenderer';

import flowStyles from '@styles/utilities/flow.module.css';
import textStyles from '@styles/textStyles.module.css';
import * as styles from './SolutionCardList.module.css';

const OFFSET_THRESHOLD = 200;

const ListItem = ({ item, onViewportEnter }) => {
  const scrollRef = useRef();

  useEffect(() => {
    const scrollHandler = () => {
      if (!scrollRef.current) return;

      const rect = scrollRef.current.getBoundingClientRect();

      if (rect.top < OFFSET_THRESHOLD && rect.top > 0) {
        onViewportEnter();
      }
    };

    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [onViewportEnter]);

  return (
    <div ref={scrollRef}>
      <CardWithIcon
        heading={item.title}
        text={item.description}
        icon={item.icon}
        items={item.listItems}
        actions={item.actions}
      />
    </div>
  );
};

export const SolutionCardList = ({
  eyebrow,
  items,
  markerColor = null,
  withBorder = false,
}) => {
  const [asideText, setAsideText] = useState(0);

  const handleItemViewportEnter = (index) => {
    setAsideText(index);
  };

  const sectionStyles = {
    '--section-marker-color': markerColor && colorMap[markerColor],
  };

  return (
    <div
      className={cn(styles.root, {
        [styles.withBorder]: withBorder,
      })}
      style={sectionStyles}
    >
      <div className={styles.text}>
        <h2 className={cn(textStyles.eyebrow)}>{eyebrow}</h2>
        <div className={styles.asideTextWrapper}>
          {items.map((item, index) => {
            return (
              <div
                key={index}
                className={cn(textStyles.headlineS, styles.asideText, {
                  [styles.asideTextActive]: asideText === index,
                })}
              >
                <TextRenderer text={item.asideText} />
              </div>
            );
          })}
        </div>
      </div>
      <div className={cn(styles.list, flowStyles.root, flowStyles.small)}>
        <AnimateIn>
          {items.map((item, index) => (
            <ListItem
              key={index}
              item={item}
              onViewportEnter={() => handleItemViewportEnter(index)}
            />
          ))}
        </AnimateIn>
      </div>
    </div>
  );
};
