import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { truncate } from '@util/strings';
import { timeAgo } from '@util/date';

import { getRegistry } from '@constants/registries';

import Link from '@components/Link';
import Tag from '@components/Tag';

import * as styles from './PackageMiniCard.module.css';

const PackageMiniCard = ({
  name,
  registry,
  keywords = [],
  description,
  latestVersion,
  latestReleaseDate,
  licenses = [],
  href,
}) => {
  const registryInfo = getRegistry(registry);
  const RegistryIcon = registryInfo.icon;

  const visibleKeywords = useMemo(() => {
    return keywords.slice(0, 10);
  }, [keywords]);

  const inner = (
    <article className={styles.root}>
      <header className={styles.header}>
        <h2 className={styles.title}>
          <span aria-hidden className={styles.registry}>
            <RegistryIcon />
          </span>
          {name}
        </h2>
      </header>
      <main className={styles.main}>
        <p className={styles.description}>{truncate(description, 30)}</p>
      </main>
      <footer className={styles.footer}>
        <ul className={styles.keywords}>
          {visibleKeywords.map((keyword) => (
            <Tag key={keyword} as="li">
              {keyword}
            </Tag>
          ))}
        </ul>

        <div className={styles.footerRow}>
          <div className={styles.version}>
            <div className={styles.versionName}>{latestVersion}</div>
            <div className={styles.versionDate}>
              {timeAgo(latestReleaseDate)}
            </div>
          </div>
          <div className={styles.license}>{licenses.join(', ')}</div>
        </div>
      </footer>
    </article>
  );

  return href ? (
    <Link className={styles.link} href={href}>
      {inner}
    </Link>
  ) : (
    inner
  );
};

PackageMiniCard.propTypes = {
  name: PropTypes.string.isRequired,
  registry: PropTypes.string.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string),
  licenses: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  latestVersion: PropTypes.string,
  latestReleaseDate: PropTypes.string,
  href: PropTypes.string,
};

export default PackageMiniCard;
