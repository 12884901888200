import React from 'react';
import cn from 'classnames';

import Link from '@components/Link';
import WebinarTeaser from '@components/webinar/WebinarTeaser';

import { isArchivedWebinar } from '@util/webinars';
import { webinarRoute } from '@util/routes';

import * as styles from './WebinarList.module.css';
import textStyles from '@styles/textStyles.module.css';

export const WebinarList = ({
  as = 'section',
  items,
  title,
  withBorder,
  withDetails = false,
  withCTA = true,
}) => {
  const Comp = as;
  return (
    <Comp className={styles.root} data-border={withBorder ? 'true' : null}>
      <header className={styles.header}>
        <h2 className={cn(textStyles.eyebrow, styles.title)}>{title}</h2>
      </header>
      <main className={styles.list}>
        {items.map((webinar) => {
          const isArchived = isArchivedWebinar(webinar);
          return (
            <div className={styles.item} key={webinar.slug}>
              <Link href={webinarRoute(webinar.slug)}>
                <WebinarTeaser
                  title={webinar.title}
                  callToAction={
                    withCTA ? (isArchived ? 'Watch now' : 'Register now') : ''
                  }
                  cover={webinar.cover}
                  date={webinar.date}
                  duration={webinar.duration}
                  description={webinar.description}
                  theme={webinar.coverTheme}
                  variant="primary"
                  withPlayIcon={isArchived}
                  focusItems={webinar.focusItems}
                  speakers={webinar.speakers}
                  withDetails={withDetails}
                />
              </Link>
            </div>
          );
        })}
      </main>
    </Comp>
  );
};
