import { TextList } from '@blocks/IconTextList';

export const PressReleasesList = ({ pressReleases, actionLabel, title }) => {
  let items = [];

  pressReleases.forEach((pressRelease) => {
    items.push({
      title: pressRelease.title,
      description: pressRelease.description,
      actions: [
        {
          label: actionLabel,
          href: `/company/press/${pressRelease.slug}`,
        },
      ],
    });
  });

  return (
    <TextList
      items={items}
      titleTextSize="medium"
      theme="light"
      title={title}
    />
  );
};
