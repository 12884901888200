import { Divider } from '@blocks/Divider';
import { PricingCards } from '@blocks/PricingCards';
import { PricingTable } from '@blocks/PricingTable';

import OnDemandPricing from './OnDemandPricing';
import PageIntro from './PageIntro';

export const PricingCardsWithTable = ({
  order,
  featureGroups,
  caption,
  additionalPricing,
  committedAdditionalPricing,
}) => {
  return (
    <>
      <PageIntro caption={caption} />
      <PricingCards plans={order} />
      <OnDemandPricing
        additionalPricing={additionalPricing}
        committedAdditionalPricing={committedAdditionalPricing}
      />
      <Divider withBorder={true} size="large" />
      <PricingTable features={featureGroups} plans={order} />
    </>
  );
};
