import cn from 'classnames';

import StaggerAnimateIn from '@components/StaggerAnimateIn';
import TeaserCardWithImage from '@components/TeaserCardWithImage';

import gridStyles from '@styles/layouts/grid.module.css';

import { withPageSection } from '@components/PageSection';
import { customerRoute } from '@util/routes';

export const TeaserCardGrid = withPageSection(({ layout, items }) => {
  return (
    <StaggerAnimateIn
      className={cn(gridStyles.row)}
      childrenClassName={cn(gridStyles.column)}
      childrenStyle={{
        '--column-span-medium': layout === 'horizontal' ? 12 : 6,
        '--column-span-large': layout === 'horizontal' ? 12 : 4,
      }}
    >
      {items.map((item, index) => (
        <TeaserCardWithImage
          key={index}
          title={item.title}
          image={item.image}
          description={item.text}
          href={item.href}
          linkText={item.linkText}
        />
      ))}
    </StaggerAnimateIn>
  );
});

export const CaseStudyListing = withPageSection(({ data, customerStories }) => {
  let array = data;

  if (customerStories) {
    array = customerStories;
  }

  return (
    <StaggerAnimateIn
      className={cn(gridStyles.row)}
      childrenClassName={cn(gridStyles.column)}
      childrenStyle={{
        '--column-span-medium': 6,
        '--column-span-large': 4,
      }}
    >
      {array.map((item, index) => (
        <div key={index}>
          <TeaserCardWithImage
            title={item.title}
            image={item.landscape}
            description={item.teaserText}
            href={customerRoute(item.slug.current)}
          />
        </div>
      ))}
    </StaggerAnimateIn>
  );
});
