import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useInView } from 'framer-motion';

import { defaultColor, colorNames, colorMap } from '@constants/colors';
import ArrowRight from '@icons/ArrowRight.svg';

import textStyles from '@styles/textStyles.module.css';

import * as styles from './ProgressionCard.module.css';

const ProgressionCard = ({
  after,
  before,
  className,
  color = defaultColor,
  showLabel = true,
}) => {
  const ref = useRef(null);
  const inView = useInView(ref, { amount: 0.5, once: true });

  useEffect(() => {
    if (inView && ref.current) {
      ref.current.classList.add(styles.inView);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      className={cn(styles.root, className)}
      style={{
        '--progression-card-accent-color': colorMap[color],
      }}
    >
      <div className={cn(styles.before)}>
        {showLabel && (
          <span className={cn(styles.label, textStyles.eyebrow)}>
            {before.label}
          </span>
        )}
        <span className={cn(styles.value, textStyles.bodyM)}>
          {before.value}
        </span>
      </div>
      <div className={cn(styles.arrow)} aria-hidden>
        <ArrowRight className={cn(styles.arrowIcon)} />
      </div>
      <div className={cn(styles.after)}>
        {showLabel && (
          <span className={cn(styles.label, textStyles.eyebrow)}>
            {after.label}
          </span>
        )}
        <span className={cn(styles.value, textStyles.bodyL)}>
          {after.value}
        </span>
      </div>
    </div>
  );
};

ProgressionCard.propTypes = {
  before: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  after: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  showLabel: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf(colorNames),
};

export default ProgressionCard;
