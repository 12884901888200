import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import TickList from '@components/TickList';
import TextRenderer from '@components/TextRenderer';

import textStyles from '@styles/textStyles.module.css';
import * as styles from './CardWithTickList.module.css';

const CardWithTickList = ({
  heading,
  renderHeadlineAs = 'h4',
  text,
  items,
}) => {
  const Headline = renderHeadlineAs;

  return (
    <div className={cn(styles.root)}>
      <Headline className={cn(styles.heading, textStyles.headlineXS)}>
        {heading}
      </Headline>
      {text && (
        <TextRenderer
          className={cn(styles.text, textStyles.body)}
          text={text}
        />
      )}

      {items && <TickList items={items} className={cn(styles.list)} />}
    </div>
  );
};

CardWithTickList.propTypes = {
  heading: PropTypes.string.isRequired,
  renderHeadlineAs: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  text: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
};

export default CardWithTickList;
