import cn from 'classnames';

import AnimateIn from '@components/AnimateIn';
import Actions from '@components/Actions';
import IconWithText from '@components/IconWithText';
import TickList from '@components/TickList';

import textStyles from '@styles/textStyles.module.css';
import flowStyles from '@styles/utilities/flow.module.css';
import TextRenderer from '@components/TextRenderer';

import { withPageSection } from '@components/PageSection';

import * as styles from './IconTextList.module.css';

export const IconTextList = withPageSection(
  ({ items }) => {
    return (
      <div className={cn(flowStyles.root, flowStyles.large)}>
        <AnimateIn>
          {items.map((item, index) => (
            <div className={styles.item} key={index}>
              <IconWithText
                heading={item.title}
                text={item.description}
                items={item.listItems}
                icon={item.icon}
                actions={item.actions}
              />
            </div>
          ))}
        </AnimateIn>
      </div>
    );
  },
  {
    layout: 'horizontal',
    contentWidth: 'default',
  },
);

export const TextList = withPageSection(
  ({ items }) => {
    return (
      <ol className={cn(styles.list)}>
        <AnimateIn tag="li">
          {items.map((item, index) => (
            <div
              key={index}
              className={cn(styles.item, {
                [styles.unobtrusive]: item.unobtrusive,
              })}
            >
              {item.title && (
                <div className={cn(styles.itemTitle, textStyles.headlineXS)}>
                  {item.title}
                </div>
              )}
              <TextRenderer
                className={cn(styles.itemDescription, textStyles.bodyM)}
                text={item.description}
              />
              {item.listItems && (
                <TickList
                  className={cn(styles.itemList)}
                  items={item.listItems}
                />
              )}
              {item.actions && (
                <Actions actions={item.actions} className={styles.actions} />
              )}
            </div>
          ))}
        </AnimateIn>
      </ol>
    );
  },
  {
    layout: 'horizontal',
    contentWidth: 'default',
  },
);
