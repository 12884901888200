export const mapRange = (value, inMin, inMax, outMin, outMax) => {
  return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
};

export const makeSparkLine = (data, width, height, dataPadding = 0.2) => {
  const min = Math.min(...data);
  const max = Math.max(...data);

  const points = data.map((value, index) => {
    const x = index * (width / (data.length - 1));
    const y = mapRange(
      value,
      min,
      max,
      height * (1 - dataPadding),
      dataPadding,
    );

    return { x, y };
  });

  return points;
};

export const sparkLineToPath = (points) => {
  return `M${points.map((point) => `${point.x}, ${point.y}`).join('L')}`;
};
