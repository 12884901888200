import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import PlayIcon from '@icons/Play.svg';
import Image from '@components/Image';
import SanityImage from '@components/SanityImage';

import * as styles from './VideoPlayer.module.css';

const VideoPlayer = ({
  className,
  title,
  thumbnail,
  thumbnailClassName,
  id,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <div className={cn(styles.root, className)}>
      {isPlaying ? (
        <div
          className={cn(styles.videoWrapper, {
            [styles.videoWrapperPlaying]: isPlaying,
          })}
        >
          <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/${id}?autoplay=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      ) : (
        <button
          className={cn(styles.thumbnailContainer, thumbnailClassName)}
          aria-label="Play Video"
          onClick={() => setIsPlaying(true)}
        >
          {thumbnail &&
            (typeof thumbnail === 'string' ? (
              <Image
                src={thumbnail}
                alt={title}
                fill
                className={cn(styles.thumbnail)}
              />
            ) : (
              <SanityImage
                asset={thumbnail}
                alt={title}
                fill
                className={cn(styles.thumbnail)}
              />
            ))}
          <span className={cn(styles.playButton)}>
            <PlayIcon />
          </span>
        </button>
      )}
    </div>
  );
};

VideoPlayer.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  thumbnail: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  thumbnailClassName: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default VideoPlayer;
