import cn from 'classnames';

import AnimateIn from '@components/AnimateIn';
import ProgressionCard from '@components/ProgressionCard';
import { withPageSection } from '@components/PageSection';

import flowStyles from '@styles/utilities/flow.module.css';

export const ProgressionCardList = withPageSection(
  ({ beforeLabel, afterLabel, accentColor = 'blue', items }) => {
    return (
      <div className={cn(flowStyles.root, flowStyles.small)}>
        <AnimateIn>
          {items.map((item, index) => (
            <ProgressionCard
              key={index}
              animate
              showLabel={index === 0}
              before={{
                label: beforeLabel,
                value: item.before,
              }}
              after={{
                label: afterLabel,
                value: item.after,
              }}
              color={accentColor}
            />
          ))}
        </AnimateIn>
      </div>
    );
  },
  {
    layout: 'horizontal',
    contentWidth: 'wide',
  },
);
