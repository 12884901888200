import cn from 'classnames';

import SanityImage from '@components/SanityImage';
import Image from '@components/Image';
import Frame from '@components/Frame';
import TickList from '@components/TickList';

import gridStyles from '@styles/layouts/grid.module.css';
import textStyles from '@styles/textStyles.module.css';
import measureStyles from '@styles/utilities/measures.module.css';

import * as styles from './FeatureSectionWithImage.module.css';

export const FeatureSectionWithImage = ({
  heading,
  listItems,
  image,
  description,
}) => {
  return (
    <div className={styles.root}>
      <div className={cn(gridStyles.row)}>
        <div
          className={cn(gridStyles.column)}
          style={{
            '--column-span-large': 6,
          }}
        >
          <h2
            className={cn(
              styles.heading,
              textStyles.headlineL,
              measureStyles.ultraNarrow,
            )}
          >
            {heading}
          </h2>
        </div>
        <div
          className={cn(
            styles.description,
            textStyles.bodyL,
            gridStyles.column,
          )}
          style={{
            '--column-span-large': 6,
          }}
        >
          <p className={cn(textStyles.bodyL, measureStyles.medium)}>
            {description}
          </p>
          {listItems && (
            <TickList items={listItems} className={cn(styles.list)} />
          )}
        </div>
      </div>
      <Frame
        className={cn(styles.media)}
        size="large"
        theme={image.background ?? 'light'}
        gradient={image.gradient}
        invertGradient={image.invertGradient}
      >
        {image.src != null ? (
          <Image fill src={image.src} alt={image.alt} sizes="100vw" />
        ) : (
          <SanityImage
            asset={image.image.asset}
            alt={image.alt}
            sizes="100vw"
          />
        )}
      </Frame>
    </div>
  );
};
