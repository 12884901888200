import cn from 'classnames';

import Button from '@components/Button';
import TextRenderer from '@components/TextRenderer';

import interactionHooks from '@util/interactions';
import siteInfo from '@content/default.json';

import * as styles from './PageIntro.module.css';

const PageIntro = ({ caption }) => {
  const openChat = interactionHooks.openChat();

  return (
    <div className={cn(styles.root)}>
      <div className={cn(styles.buttons)}>
        <Button
          href={siteInfo.signUpUrl}
          size="xlarge"
          trackingScope="PricingPage"
          withArrow
          isStartFreeTrial
        >
          Start your free trial
        </Button>
        <Button
          variant="secondary"
          size="xlarge"
          trackingScope="PricingPage"
          onClick={openChat}
        >
          Talk to sales
        </Button>
      </div>
      <div className={cn(styles.caption)}>
        <TextRenderer text={caption} />
      </div>
    </div>
  );
};

export default PageIntro;
