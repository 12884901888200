import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import * as styles from './Frame.module.css';

import AnimateIn from '@components/AnimateIn';
import { colorNames, colorMap } from '@constants/colors';

const Frame = ({
  children,
  theme = 'light',
  gradient = 'purple',
  invertGradient = false,
  size = 'default',
  bleed,
  className,
}) => {
  return (
    <div
      className={cn(
        styles.root,
        {
          [styles.hasGradient]: gradient !== null,
          [styles.invertGradient]: invertGradient,
          [styles.dark]: theme === 'dark',
          [styles.large]: size === 'large',
          [styles.bleedBottom]: bleed === 'bottom',
        },
        className,
      )}
      style={{
        '--frame-gradient-color': gradient && colorMap[gradient],
      }}
    >
      <AnimateIn className={cn(styles.imageWrapper)}>{children}</AnimateIn>
    </div>
  );
};

Frame.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.oneOf(['light', 'dark']),
  highlightColor: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  padding: PropTypes.object,
  gradient: PropTypes.oneOf([null, ...colorNames]),
  invertGradient: PropTypes.bool,
};

export default Frame;
