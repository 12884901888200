import { useState } from 'react';
import cn from 'classnames';
import { m as motion, LazyMotion, domAnimation } from 'framer-motion';

import flowStyles from '@styles/utilities/flow.module.css';
import * as styles from './Accordion.module.css';

import Actions from '@components/Actions';
import TextRenderer, { RichTextRenderer } from '@components/TextRenderer';
import { withPageSection } from '@components/PageSection';

import ChevronDown from '@icons/ChevronDown.svg';

export const Accordion = withPageSection(({ items }) => {
  const [activeId, setActiveId] = useState(null);
  const preparedItems = items.map((item, index) => ({
    ...item,
    id: `accordion-item-${index}`,
  }));

  const toggleItem = (id) => {
    if (activeId === id) {
      setActiveId(null);
      return;
    }

    setActiveId(id);
  };

  return (
    <LazyMotion features={domAnimation}>
      <ol className={cn(styles.root)}>
        {preparedItems.map(({ id, title, content, actions }) => (
          <li key={id} className={styles.item}>
            <button className={cn(styles.label)} onClick={() => toggleItem(id)}>
              <motion.span
                className={styles.icon}
                variants={{
                  closed: {
                    rotate: 0,
                  },
                  open: {
                    rotate: 180,
                  },
                }}
                initial="closed"
                animate={activeId === id ? 'open' : 'closed'}
                transition={{
                  ease: 'easeInOut',
                }}
              >
                <ChevronDown />
              </motion.span>
              <span className={styles.title}>{title}</span>
            </button>
            <motion.div
              className={styles.content}
              variants={{
                closed: {
                  height: 0,
                  pointerEvents: 'none',
                  overflow: 'hidden',
                },
                open: {
                  height: 'auto',
                  pointerEvents: 'auto',
                },
              }}
              initial="closed"
              animate={activeId === id ? 'open' : 'closed'}
              transition={{
                ease: 'easeInOut',
              }}
            >
              {Array.isArray(content) ? (
                <RichTextRenderer
                  text={content}
                  className={cn(
                    styles.contentText,
                    flowStyles.root,
                    flowStyles.small,
                  )}
                />
              ) : (
                <TextRenderer
                  // Put JSON markdown content in div but sanity content in p
                  as={content.startsWith('<p>') ? 'div' : 'p'}
                  text={content}
                  className={cn(
                    styles.contentText,
                    flowStyles.root,
                    flowStyles.small,
                  )}
                />
              )}

              {actions && (
                <Actions actions={actions} className={styles.contentActions} />
              )}
            </motion.div>
          </li>
        ))}
      </ol>
    </LazyMotion>
  );
});
