import CardWithTickList from '@components/CardWithTickList';
import Carousel from '@components/Carousel';
import TwitterCard from '@components/TwitterCard';
import TestimonialCard from '@components/TestimonialCard';
import { withPageSection } from '@components/PageSection';

export const CardCarousel = withPageSection(
  ({
    animateCardsIn = true,
    cardType,
    cards,
    eyebrow,
    heading,
    stretchCards = true,
  }) => {
    let items;

    switch (cardType) {
      case 'twitter':
        items = cards.map((item, index) => (
          <TwitterCard
            key={index}
            author={item.author}
            twitterName={item.twitterName}
            twitterAvatar={item.twitterAvatar}
            quote={item.quote}
          />
        ));
        break;
      case 'tickList':
        items = cards.map((item, index) => (
          <CardWithTickList
            key={index}
            heading={item.title}
            text={item.description}
            items={item.listItems}
          />
        ));
        break;
      case 'testimonial':
        items = cards.map((item, index) => (
          <TestimonialCard
            key={index}
            author={item.author}
            authorTitle={item.authorTitle}
            companyLogo={item.companyLogo}
            companyName={item.companyName}
            quote={item.quote}
            quoteSize={item.quoteSize}
            gradient={item.gradient}
            href={item.href}
          />
        ));
        break;
      default:
        items = [];
    }

    return (
      <Carousel
        animate={animateCardsIn}
        heading={heading}
        eyebrow={eyebrow}
        stretch={stretchCards}
      >
        {items}
      </Carousel>
    );
  },
);
