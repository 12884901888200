import React from 'react';
import cn from 'classnames';

import ArrowRightIcon from '@icons/ArrowRight.svg';

import Link from '@components/Link';
import Button from '@components/Button';
import VideoPlayer from '@components/VideoPlayer';
import { withPageSection } from '@components/PageSection';

import textStyles from '@styles/textStyles.module.css';
import measureStyles from '@styles/utilities/measures.module.css';
import * as styles from './Resources.module.css';

const head = (x) => x[0];
const tail = (x) => x.slice(1, Infinity);

export const Resources = withPageSection(
  ({
    label = 'Resources',
    heading,
    headingTextSize = 'large',
    intro,
    links,
    video,
  }) => {
    const headingClassName = cn(
      {
        [textStyles.headlineM]: headingTextSize === 'medium',
        [textStyles.headlineL]: headingTextSize === 'large',
      },
      {
        [measureStyles.narrow]: headingTextSize === 'medium',
        [measureStyles.ultraNarrow]: headingTextSize === 'large',
      },
    );

    const buttonLink = head(links);
    const secondaryLinks = tail(links);

    return (
      <div className={styles.root}>
        <div className={styles.linkColumn}>
          <div>
            <div className={cn(textStyles.eyebrow)}>{label}</div>
            <h2 className={headingClassName}>{heading}</h2>
            {intro && (
              <p className={cn(textStyles.bodyL, styles.description)}>
                {intro}
              </p>
            )}
          </div>
          {buttonLink && (
            <div className={styles.buttonLink}>
              <Button withArrow href={buttonLink.url} colorScheme="light">
                {buttonLink.title}
              </Button>
            </div>
          )}
          {secondaryLinks.length > 0 && (
            <div className={cn(styles.links)}>
              {secondaryLinks.map((link) => (
                <Link
                  prefetch={false}
                  key={link.title}
                  className={styles.link}
                  href={link.url}
                >
                  <span className={styles.linkText}>{link.title}</span>
                  <ArrowRightIcon className={styles.linkIcon} />
                </Link>
              ))}
            </div>
          )}
        </div>
        {video && (
          <div className={styles.videoColumn}>
            <VideoPlayer
              title={video.title}
              thumbnail={video.thumbnail}
              id={video.youtubeId ?? video.id}
            />
          </div>
        )}
      </div>
    );
  },
);
