import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from '@components/Button';
import Image from '@components/Image';
import SanityImage from '@components/SanityImage';
import Link from '@components/Link';
import TextRenderer from '@components/TextRenderer';

import measureStyles from '@styles/utilities/measures.module.css';
import textStyles from '@styles/textStyles.module.css';
import * as styles from './TeaserCardWithImage.module.css';

const TeaserCardWithImage = ({
  title,
  image,
  description,
  href,
  linkText = 'Read more',
  renderHeadlineAs = 'h4',
}) => {
  const Headline = renderHeadlineAs;

  return (
    <Link prefetch={false} href={href} className={cn(styles.root)}>
      <article className={cn(styles.innerWrapper)}>
        <div className={cn(styles.imageWrapper)}>
          {image.src ? (
            <Image
              src={image.src}
              alt={image.alt}
              fill
              sizes="(min-width: 960px) 33vw, 100vw"
            />
          ) : (
            <SanityImage
              asset={image.image.asset}
              alt={image.alt}
              fill
              sizes="(min-width: 960px) 33vw, 100vw"
            />
          )}
        </div>
        <div className={cn(styles.textWrapper)}>
          <div>
            <Headline className={cn(styles.headline, textStyles.headlineS)}>
              {title}
            </Headline>
            <TextRenderer
              className={cn(
                styles.description,
                textStyles.bodyM,
                measureStyles.medium,
              )}
              text={description}
            />
          </div>

          <Button
            className={cn(styles.button)}
            colorScheme="text"
            variant="secondary"
          >
            {linkText}
          </Button>
        </div>
      </article>
    </Link>
  );
};

TeaserCardWithImage.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      image: PropTypes.shape({
        asset: PropTypes.string.isRequired,
      }).isRequired,
      alt: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  description: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  linkText: PropTypes.string,
  renderHeadlineAs: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};

export default TeaserCardWithImage;
